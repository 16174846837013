<template>
  <figure class="icon">
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.47998 11.98H19.47"
        stroke="#007385"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M13.4829 5.98779L19.5199 11.9998L13.4829 18.0118"
        stroke="#007385"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "ArrowRightIcon",
};
</script>
