<template>
  <figure class="icon">
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.47998 11.9805H19.47"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M13.483 5.98828L19.52 12.0003L13.483 18.0123"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "ArrowRightIcon",
};
</script>
