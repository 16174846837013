<template>
  <section class="welcome">
    <div class="container">
      <div class="welcome__info">
        <h1 class="welcome__title">Обмен <span class="underline">знаниями</span> без границ</h1>
        <div class="welcome__text">
          Etikaego — единственная образовательная онлайн-площадка для лекций, курсов, мастер-классов от
          мусульман для мусульман, а также единственное в своем роде место знакомства и обмена знаниями в
          сфере исламской тематики, арабистики и востоковедения.
        </div>
        <div class="welcome__buttons">
          <button class="btn btn--white" type="button" @click="showRegisterModal">
            Бесплатная регистрация
          </button>
          <button class="btn btn--white-outline" type="button" @click="showRegisterSchoolModal">
            Открыть школу
          </button>
        </div>
        <div class="welcome__stats">
          <div class="welcome-stat">
            <div class="welcome-stat__number">{{ counts.users_count || 0 }}</div>
            <div class="welcome-stat__label">Пользователей</div>
          </div>
          <div class="welcome-stat">
            <div class="welcome-stat__number">{{ counts.lessons_count || 0 }}</div>
            <div class="welcome-stat__label">Лекций</div>
          </div>
          <div class="welcome-stat">
            <div class="welcome-stat__number">{{ counts.teachers_count || 0 }}</div>
            <div class="welcome-stat__label">Лекторов</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RegisterModal from "components/modals/components/RegisterModal.vue";
import RegisterSchoolModal from "components/modals/components/RegisterSchoolModal.vue";

export default {
  name: "HomeWelcomeComponent",
  props: {
    counts: {
      type: Object,
    },
  },
  methods: {
    showRegisterModal() {
      this.$store.state._modals.push({
        component: RegisterModal,
      });
    },
    showRegisterSchoolModal() {
      this.$store.state._modals.push({
        component: RegisterSchoolModal,
      });
    },
  },
};
</script>

<style lang="stylus">
.welcome {
  position relative
  background-color var(--main_color)
  color var(--white)
  background-image url("/static/images/welcome.png")
  background-repeat no-repeat
  background-position bottom right 9%
  background-size: auto 115%;
  overflow: hidden;
  padding 60px 32px
  +below(640px) {
    padding 30px 20px
  }

  ::selection {
    background: var(--white);
    color var(--main_color)
    text-shadow none
  }

  ::-moz-selection {
    background: var(--white);
    color var(--main_color)
    text-shadow none
  }

  &::after {
    content ""
    display block
    width 233px
    height 224px
    position absolute
    right -107px
    top -12px
    z-index 1
    background url("/static/images/welcome.png")
    background-repeat no-repeat
    background-position center
    background-size contain
    transform: matrix(0.87, -0.5, 0.5, 0.87, 0, 0);
    +above(421px) {
      display none
    }
  }
  +below(1450px) {
    background-position bottom right -100px
  }
  +below(1150px) {
    background-position bottom right -200px
  }
  +below(960px) {
    background-image none
  }
  +below(560px) {
    padding-top 30px
    padding-bottom: 30px
  }

  &__info {
    position relative
    z-index 2
    max-width 555px // 588px
  }

  &__title {
    font-weight: bold;
    font-size: 3.75em
    line-height: 120%
    margin-bottom: 28px;
    +below(768px) {
      font-size: 3em
      margin-bottom: 24px;
    }
    +below(560px) {
      font-size: 2.5em
    }
    +below(420px) {
      font-size: 1.875em
      margin-bottom: 20px;
      max-width: 75%;
    }

    span.underline {
      position: relative;

      &::after {
        content ""
        display block
        width 100%
        height 0.25em
        position absolute
        left 0
        right 0
        bottom -0.1em
        z-index 1
        background url("/static/images/underline.svg")
        background-repeat no-repeat
        background-position center
        background-size contain
      }
    }
  }

  &__text {
    font-size: 1.5em
    line-height: 140%;
    margin-bottom: 40px;
    +below(768px) {
      font-size: 1.25em
      margin-bottom: 35px;
    }
    +below(560px) {
      font-size 1.125em
      margin-bottom: 30px;
    }
    +below(420px) {
      font-size 1em
    }
  }

  &__buttons {
    display flex
    gap 14px
    margin-bottom: 22px;
    +below(420px) {
      flex-wrap wrap
    }

    .btn {
      &--white {
        width 290px
      }

      &--white-outline {
        width 210px
      }
      +below(420px) {
        width 100%
      }
    }
  }

  &__stats {
    display flex
    flex-flow row wrap
    margin -20px
    +below(768px) {
      margin: -15px;
    }
    +below(560px) {
      margin: -12px;
    }
    +below(420px) {
      margin: -10px;
    }
  }
}

.welcome-stat {
  margin: 20px;
  +below(768px) {
    margin: 15px;
  }
  +below(560px) {
    margin: 12px;
  }
  +below(420px) {
    margin: 10px;
  }

  &__number {
    font-weight: bold;
    font-size: 3.75em
    line-height: 120%
    +below(768px) {
      font-size: 3em
    }
    +below(560px) {
      font-size: 2em
    }
    +below(420px) {
      font-size: 1.5em
    }
  }

  &__label {
    font-size: 1.5em
    line-height: 140%;
    +below(768px) {
      font-size: 1.25em
    }
    +below(560px) {
      font-size: 1em
    }
    +below(420px) {
      font-size: 0.875em
    }
  }
}
</style>
