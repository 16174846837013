<template>
  <section class="faqs" v-if="faqs.length">
    <div class="container faqs__container">
      <h2 class="faqs__title">Часто задаваемые вопросы</h2>
      <ul class="faqs__list">
        <li class="faq" v-for="(faq, i) in faqs" :key="i" :class="{ 'faq--active': isActive(i) }">
          <div class="faq__title" @click="setActive(i)">
            <span>{{ faq.title }}</span>
            <DownIcon />
          </div>
          <div class="faq__description">{{ faq.answer }}</div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import DownIcon from "components/svg/DownIcon.vue";

export default {
  name: "HomeFaqComponent",
  props: {
    faqs: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      activeId: null,
    };
  },
  methods: {
    setActive(id) {
      if (id === this.activeId) {
        this.activeId = null;
      } else {
        this.activeId = id;
      }
    },
    isActive(id) {
      return this.activeId === id;
    },
  },
  components: {
    DownIcon,
  },
};
</script>

<style lang="stylus">
.faqs {
  padding 0 32px
  +below(640px) {
    padding 0 20px
  }

  &__container {
    display grid
    grid-gap 40px
    grid-template-columns 9fr 3fr
    +below(1120px) {
      grid-template-columns 1fr
    }
  }

  &__title {
    font-weight: bold;
    font-size: 3em;
    line-height: 58px;
    margin 0
    +below(1120px) {
      font-size: 2.75em
      line-height: normal;
    }
    +below(640px) {
      font-size: 1.875em
      line-height: 36px;
    }
  }

  &__list {
    border 1px solid var(--border_color)
    border-radius 6px
    grid-column 1
  }
}

.faq {
  border-bottom 1px solid var(--border_color)

  &:last-child {
    border-bottom: none
  }

  &__title {
    display flex
    align-items center
    justify-content space-between
    font-weight: bold;
    font-size: 1.25em
    line-height: 140%;
    cursor pointer
    transition var(--transition)
    padding 27px 20px 30px 33px
    gap 52px
    +below(768px) {
      padding 20px
      gap 32px
    }
    +below(560px) {
      font-size 1.125em
    }
    +below(420px) {
      font-size 1em
      padding 15px
    }

    &:hover {
      color var(--main_color)
    }

    .icon svg {
      transition var(--transition)
    }

    ^[0]--active & {
      padding-bottom: 16px;
      +below(420px) {
        padding-bottom: 10px;
      }

      .icon svg {
        transform rotate(180deg)
      }
    }
  }

  &__description {
    width: 90%;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: var(--transition)
    padding-left 33px
    +below(768px) {
      padding-left 20px
    }
    +below(420px) {
      font-size 0.875em
      padding-left 15px
    }

    ^[0]--active & {
      visibility: visible;
      height: auto;
      overflow: visible;
      opacity: 1;
      padding-bottom 30px
      +below(768px) {
        padding-bottom 20px
      }
      +below(420px) {
        padding-bottom 15px
      }
    }
  }
}
</style>
