<template>
  <section class="courses" v-if="courses.length">
    <div class="container courses__container">
      <h2 class="courses__title">Выбор пользователей</h2>
      <div class="courses__list">
        <CourseComponent
          v-for="(course, i) in courses"
          @favorite="addToFavorites(course.id)"
          :key="i"
          :course="course"
          :favorite="isInFavorites(course.id)"
          fixed
        />
      </div>
    </div>
  </section>
</template>

<script>
import CourseComponent from "components/CourseComponent.vue";

export default {
  name: "HomeCoursesComponent",
  props: {
    courses: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      favoritesIdList: [],
    };
  },
  methods: {
    addToFavorites(id) {
      const index = this.favoritesIdList.indexOf(id);
      if (index !== -1) {
        this.favoritesIdList.splice(index, 1);
      } else {
        this.favoritesIdList.push(id);
      }
    },
    isInFavorites(id) {
      return this.favoritesIdList.indexOf(id) !== -1;
    },
  },
  components: {
    CourseComponent,
  },
};
</script>

<style lang="stylus">
.courses {
  padding 0 32px
  +below(640px) {
    padding 0 20px
  }
  &__container {
    display grid
    grid-gap 48px
    +below(768px) {
      grid-gap 38px
    }
    +below(560px) {
      grid-gap 30px
    }
  }

  &__title {
    font-weight: bold;
    font-size: 3em;
    line-height: 58px;
    margin 0
    +below(1120px) {
      font-size: 2.75em
      line-height: normal;
    }
    +below(540px) {
      font-size: 1.875em
      line-height: 36px;
    }
  }

  &__list {
    display grid
    grid-template-columns repeat(4, 1fr)
    grid-gap 20px
    +below(1240px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(970px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(680px) {
      grid-template-columns 1fr
    }
  }
}
</style>
