<template>
  <section class="categories" v-if="categories.length">
    <div class="container categories__container">
      <div class="categories__header">
        <h2 class="categories__title">Популярные категории</h2>
        <router-link :to="{ name: 'courses' }" class="btn btn--main-outline">
          <span>Все категории</span>
          <ArrowRightIcon />
        </router-link>
      </div>
      <div class="categories__list">
        <swiper ref="swiper" :options="swiperOptions">
          <swiper-slide v-for="(category, i) in categories" :key="i">
            <div class="category">
              <img
                :alt="category.head_img | image_alt"
                class="category__img"
                :src="category.head_img | image($store.state._env.MEDIA_ENDPOINT)"
              />
              <div class="category__info">
                <h3 class="category__title">{{ category.title }}</h3>
                <p class="category__description">{{ category.description }}</p>
              </div>
              <div class="category__btn">
                <router-link
                  :to="{ name: 'course_category', params: { link: category.link } }"
                  class="btn btn--gray-outline"
                >
                  <span>{{ category.courses_count | plural("курс", "курса", "курсов") }}</span>
                  <ArrowRightBlack />
                </router-link>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <router-link :to="{ name: 'courses' }" class="btn btn--main-outline btn--medium categories__bottom-btn">
        <span>Все категории</span>
        <ArrowRightIcon />
      </router-link>
    </div>
  </section>
</template>

<script>
import { directive, Swiper, SwiperSlide } from "vue-awesome-swiper";
import ArrowRightIcon from "components/svg/ArrowRightIcon.vue";
import ArrowRightBlack from "components/svg/ArrowRightBlack.vue";

export default {
  name: "HomeCategoriesComponent",
  props: {
    categories: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1.05,
        spaceBetween: -45,
        loop: false,
        clickable: true,
        breakpoints: {
          440: {
            slidesPerView: 1.2,
            spaceBetween: -45,
          },
          500: {
            slidesPerView: 1.4,
            spaceBetween: -45,
          },
          640: {
            slidesPerView: 1.6,
            spaceBetween: -40,
          },
          700: {
            slidesPerView: 2,
            spaceBetween: -40,
          },
          890: {
            slidesPerView: 2.5,
            spaceBetween: -40,
          },
          1097: {
            slidesPerView: 3,
            spaceBetween: -40,
          },
        },
      },
    };
  },
  directives: {
    swiper: directive,
  },
  computed: {
    swiper() {
      return this.$refs.swiper?.$swiper;
    },
  },
  methods: {
    getImage(category) {
      if (category.icon) {
        return category.icon;
      }
      return "/static/images/category.png";
    },
  },
  components: {
    ArrowRightIcon,
    ArrowRightBlack,
    Swiper,
    SwiperSlide,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/elements/swiper.styl"
.categories {
  padding 0 32px
  +below(1096px) {
    padding 0
  }

  &__container {
    padding 100px 90px
    background var(--gray_blue)
    display grid
    grid-gap 50px
    border-radius 50px
    +below(1096px) {
      padding 30px 20px
      border-radius 0
    }
    +below(640px) {
      grid-gap 30px
    }
  }

  &__header {
    display flex
    align-items center
    justify-content space-between

    .btn {
      +below(1096px) {
        display none
      }
    }
  }

  &__title {
    font-weight: bold;
    font-size: 3em;
    line-height: 58px;
    margin 0
    +below(1120px) {
      font-size: 2.75em
      line-height: normal;
    }
    +below(640px) {
      font-size: 1.875em
      line-height: 36px;
    }
  }

  &__list {
    margin -30px
    display flex
    justify-content center
    +below(1096px) {
      margin -20px
    }

    .swiper-container {
      width 100%
    }
  }

  &__bottom-btn {
    display none
    margin-top 30px
    margin-left auto
    +below(1100px) {
      display flex
    }
    +below(420px) {
      width 100%
    }
  }
}

.category {
  position relative
  display flex
  align-items flex-start
  flex-direction column
  box-sizing border-box
  height 467px
  background-color var(--white)
  padding 40px
  border-radius: 15px
  box-shadow: 0 16px 24px var(--black_o05), 0 2px 6px var(--black_o05), 0 0 1px var(--black_o05);
  overflow hidden
  margin: 30px;
  +below(1250px) {
    align-items center
    justify-content stretch
    padding 30px
  }
  +below(420px) {
    padding 25px 15px
    min-height auto
  }

  &__info {
    max-width 300px
    +below(1250px) {
      text-align center
      max-width 100%
    }
  }

  &__title {
    font-weight: bold;
    font-size: 1.5em
    line-height: 130%;
    margin 0 0 16px
  }

  &__description {
    opacity: 0.5;
    margin 0 0 16px
    +below(420px) {
      margin-bottom: 30px;
    }
  }

  &__btn {
    margin-top: auto;
    +below(360px) {
      width 100%
    }

    .btn {
      +below(360px) {
        width 100%
      }
    }
  }

  &__img {
    position absolute
    right -58px
    bottom -12px
    z-index 0
    width 235px
    height 235px
    max-width 235px
    max-height 235px
    transform: rotate(-15deg);
    border-radius: 15px;
    +below(1350px) {
      right: -100px;
      bottom: -40px;
    }
    +below(1250px) {
      position static
      transform: none
      width 180px
      height 180px
      max-width 180px
      max-height 180px
      margin-bottom: 30px;
    }
    +below(420px) {
      width 115px
      height 115px
      max-width 115px
      max-height 115px
    }

    img {
      width 100%
      height 100%
      border-radius: 15px;
      object-fit cover
      object-position center
    }
  }
}
</style>
