<template>
  <main class="main home">
    <HomeWelcomeComponent :counts="counts" />
    <HomeCoursesComponent :courses="courses" />
    <HomeCategoriesComponent :categories="categories" />
    <HomeFaqComponent :faqs="faqs" />
  </main>
</template>

<script>
import HomeWelcomeComponent from "./components/Welcome.vue";
import HomeCoursesComponent from "./components/Courses.vue";
import HomeCategoriesComponent from "./components/Categories.vue";
import HomeFaqComponent from "./components/Faq.vue";
import HOME_PAGE from "@/graphql/pages/HomePage.graphql";

export default {
  name: "HomePage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: HOME_PAGE,
      })
      .then(({ data }) => {
        store.state.home_page.counts.users_count = data.users_count;
        store.state.home_page.counts.lessons_count = data.lessons_count;
        store.state.home_page.counts.teachers_count = data.teachers_count;
        store.state.home_page.courses = data.courses_paginate;
        store.state.home_page.categories = data.categories_paginate;
        store.state.home_page.faqs = data.faqs;
      })
      .catch(() => {});
  },
  computed: {
    counts() {
      return this.$store.state.home_page.counts;
    },
    courses() {
      return this.$store.state.home_page.courses?.data;
    },
    categories() {
      return this.$store.state.home_page.categories?.data?.filter((c) => c.courses_count);
    },
    faqs() {
      return this.$store.state.home_page.faqs;
    },
  },
  components: {
    HomeWelcomeComponent,
    HomeCoursesComponent,
    HomeCategoriesComponent,
    HomeFaqComponent,
  },
};
</script>

<style lang="stylus">
.main.home {
  background var(--white)
  display grid
  grid-gap 100px
  padding-bottom 100px
  +below(640px) {
    grid-gap 50px
    padding-bottom 50px
  }
}
</style>
